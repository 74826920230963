import { useDomainContext } from "@hooks/useDomainContext";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";
import { useRouter } from "next/router";
import React from "react";
import styles from "./style.module.scss";
export default function AutoWarrantyMakers() {
    const { autoWarrantyMakers } = useDomainContext();
    const year = new Date().getFullYear();
    const { query } = useRouter();
    const queryString = gettingQueriesAsString(query, "start", [
        "genericRoute",
        "keyword",
        "replaceKeyword",
    ]);
    const getMakerNameForUrl = (value: string) => {
        return value.trim().toLowerCase().replace(/ /g, "-");
    };

    return (
        <>
            {autoWarrantyMakers && autoWarrantyMakers.length > 0 && (
                <section className="lg:mb-[170px] mb-[100px] lg:mt-[100px] mt-[50px] ">
                    <h2 className="text-[#424242] lg:text-[40px] text-3xl text-center">
                        Best Car Make in the United States for {year}
                    </h2>
                    <div className="flex flex-wrap mt-7  mx-auto  ">
                        {autoWarrantyMakers.map((maker) => (
                            <div
                                key={maker.id}
                                className="w-[50%] sm:w-[32%] lg:w-[20%]  flex justify-center mt-7 "
                            >
                                <a
                                    href={`/generic/reliable-protection-for-your-${getMakerNameForUrl(
                                        maker.value,
                                    )}?replaceKeyword=true${
                                        queryString
                                            ? `${
                                                  queryString.startsWith("&")
                                                      ? queryString
                                                      : `&${queryString}`
                                              }`
                                            : ""
                                    } `}
                                    className={`${styles["beforeStyle"]} text-[#3f7fea] text-lg    text-center hover:underline cursor-pointer`}
                                >
                                    {maker.label}
                                </a>
                            </div>
                        ))}
                    </div>
                </section>
            )}
        </>
    );
}
